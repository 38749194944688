svc-page-navigator,
.svc-page-navigator {
  display: flex;
  flex-direction: column;
}

.svc-page-navigator__navigator-icon {
  display: block;
  height: calc(3 * var(--base-unit, 8px));
  width: calc(3 * var(--base-unit, 8px));
  padding: calc(1.25 * var(--base-unit, 8px));
  border-radius: 50%;
  cursor: pointer;
}
.svc-page-navigator__navigator-icon use {
  fill: var(--foreground-light, #909090);
}

.svc-page-navigator__selector {
  width: calc(5.5 * var(--base-unit, 8px));
  height: calc(5.5 * var(--base-unit, 8px));
  min-height: calc(5.5 * var(--base-unit, 8px));
}

survey-creator .svc-page-navigator__selector:hover, survey-creator .svc-page-navigator__selector:focus,
.svc-creator .svc-page-navigator__selector:hover,
.svc-creator .svc-page-navigator__selector:focus {
  outline: none;
}
survey-creator .svc-page-navigator__selector:hover .svc-page-navigator__navigator-icon, survey-creator .svc-page-navigator__selector:focus .svc-page-navigator__navigator-icon,
.svc-creator .svc-page-navigator__selector:hover .svc-page-navigator__navigator-icon,
.svc-creator .svc-page-navigator__selector:focus .svc-page-navigator__navigator-icon {
  background-color: var(--primary-light, rgba(25, 179, 148, 0.1));
}
survey-creator .svc-page-navigator__selector:hover .svc-page-navigator__navigator-icon use, survey-creator .svc-page-navigator__selector:focus .svc-page-navigator__navigator-icon use,
.svc-creator .svc-page-navigator__selector:hover .svc-page-navigator__navigator-icon use,
.svc-creator .svc-page-navigator__selector:focus .svc-page-navigator__navigator-icon use {
  fill: var(--primary, #19b394);
}

.svc-page-navigator__selector--opened {
  opacity: 0.5;
}
.svc-page-navigator__selector--opened .svc-page-navigator__navigator-icon {
  background-color: var(--primary-light, rgba(25, 179, 148, 0.1));
}
.svc-page-navigator__selector--opened .svc-page-navigator__navigator-icon use {
  fill: var(--primary, #19b394);
}

.svc-page-navigator__popup {
  min-width: calc(12.5 * var(--base-unit, 8px));
}

.svc-tab-designer--with-page-navigator .svc-tab-designer_content {
  margin-right: calc(6.5 * var(--base-unit, 8px));
}

.svc-tab-designer__page-navigator {
  width: calc(5.5 * var(--base-unit, 8px));
  position: absolute;
  padding: calc(16.5 * var(--base-unit, 8px)) 0;
  top: 0;
  bottom: 0;
  right: calc(1 * var(--base-unit, 8px));
}

.svc-creator__toolbox--right .svc-tab-designer--with-page-navigator .svc-tab-designer_content,
[dir=rtl] .svc-tab-designer--with-page-navigator .svc-tab-designer_content,
[style*="direction:rtl"] .svc-tab-designer--with-page-navigator .svc-tab-designer_content,
[style*="direction: rtl"] .svc-tab-designer--with-page-navigator .svc-tab-designer_content {
  margin-right: 0;
  margin-left: calc(6.5 * var(--base-unit, 8px));
}
.svc-creator__toolbox--right .svc-tab-designer__page-navigator,
[dir=rtl] .svc-tab-designer__page-navigator,
[style*="direction:rtl"] .svc-tab-designer__page-navigator,
[style*="direction: rtl"] .svc-tab-designer__page-navigator {
  right: unset;
  left: calc(1 * var(--base-unit, 8px));
}