.svc-row {
  width: 100%;
  position: relative;
}

.svc-row:not(.svc-row--ghost) > .sd-row {
  min-height: 50px;
}

.svc-row .sd-row--multiple {
  overflow: auto;
  padding: calc(1 * var(--base-unit, 8px));
  gap: calc(1 * var(--base-unit, 8px));
  margin-top: calc(2 * var(--base-unit, 8px));
  margin-left: 0;
  width: 100%;
  flex-wrap: nowrap;
  background: var(--background, #fff);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: calcCornerRadius(1);
}
.svc-row .sd-row--multiple > div {
  padding-left: 0;
}
.svc-row .sd-row--multiple .sd-row--multiple {
  overflow: unset;
}

.sd-panel .svc-row .sd-row--multiple {
  padding: calc(0 * var(--base-unit, 8px));
  box-shadow: none;
  border-radius: 0;
  padding: 2px;
  margin: -2px;
}

.svc-row.svc-row--ghost .svc-question__content {
  padding: 0;
  border: none;
  box-shadow: none;
}
.svc-row.svc-row--ghost .sd-row {
  height: calc(4 * var(--base-unit, 8px));
}
.svc-row.svc-row--ghost:last-child .sd-row {
  height: initial;
  margin-top: calc(2 * var(--base-unit, 8px));
  margin-bottom: calc(2 * var(--base-unit, 8px));
}
.svc-row.svc-row--ghost + .svc-row .sd-page__row {
  margin-top: 0;
}

.svc-panel .svc-row.svc-row--ghost.svc-row.svc-row--ghost .sd-row {
  height: initial;
  margin-top: calc(2 * var(--base-unit, 8px));
  margin-bottom: calc(2 * var(--base-unit, 8px));
}

.svc-row--drag-over-top:before {
  content: " ";
  position: absolute;
  left: 0;
  background: var(--secondary, #ff9814);
  top: 6px;
  width: 100%;
  height: 4px;
}

.svc-row--drag-over-bottom:after {
  content: " ";
  position: absolute;
  left: 0;
  background: var(--secondary, #ff9814);
  bottom: -10px;
  width: 100%;
  height: 4px;
}

.svc-question__content--panel .svc-row--drag-over-top:before,
.sd-panel__content .svc-row--drag-over-top:before {
  top: -6px;
}
.svc-question__content--panel .svc-row--drag-over-bottom:after,
.sd-panel__content .svc-row--drag-over-bottom:after {
  bottom: -6px;
}