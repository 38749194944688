.svc-question-link__set-button {
  font-weight: 600;
  cursor: pointer;
}

.svc-link-value-button {
  margin-left: calc(-2 * var(--base-unit, 8px));
}

.svc-question-link__clear-button {
  color: var(--red, #e60a3e);
  font-weight: 600;
  cursor: pointer;
}

.svc-question-link__clear-button:focus,
.svc-question-link__clear-button:hover {
  background-color: var(--red-light, rgba(229, 10, 62, 0.1));
}