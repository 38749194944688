.svc-property-panel__group {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  padding: calc(2 * var(--base-unit, 8px));
  border: none;
  outline: none;
  color: var(--foreground-light, #909090);
  cursor: pointer;
  text-align: left;
  background: var(--background, #fff);
  box-shadow: inset 0px -1px 0px var(--border, #d6d6d6);
  font-family: var(--font-family);
  font-size: calc(2 * var(--base-unit, 8px));
}

.svc-property-panel__group:disabled,
.svc-property-panel__group:disabled:hover {
  background-color: var(--background, #fff);
  color: var(--foreground, #161616);
  opacity: 0.25;
  cursor: default;
}

.svc-property-panel__group:hover {
  background-color: var(--background-dim, #f3f3f3);
  color: var(--foreground-light, #909090);
  font-weight: 400;
}

.svc-property-panel__group--active,
.svc-property-panel__group--active:hover {
  color: var(--foreground, #161616);
  font-weight: 600;
  background-color: var(--background, #fff);
}