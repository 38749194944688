.svc-tab-logic-edit {
  width: 100%;
  height: 100%;
  background: var(--background-dim, #f3f3f3);
}

.svc-tab-logic-edit__content {
  height: calc(100% - (6 * var(--base-unit, 8px)));
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 16%;
  padding-right: 16%;
}

.svc-tab-logic-edit__content .sd-row {
  flex-wrap: wrap;
}

.svc-logic_trigger-editor .sd-row {
  margin-top: 0;
}

.svc-tab-logic-edit__content-actions .sv-action-bar {
  padding: 0;
  height: calc(6 * var(--base-unit, 8px));
  background: var(--background, #fff);
  border-top: 1px solid var(--border, #d6d6d6);
  width: 100%;
}

.svc-logic-paneldynamic div.svc-logic-operator {
  height: calc(3 * var(--base-unit, 8px));
  box-sizing: content-box;
}

.svc-logic-paneldynamic .svc-logic-operator {
  -webkit-appearance: none;
  appearance: none;
  padding: calc(1 * var(--base-unit, 8px)) calc(2 * var(--base-unit, 8px));
  border-radius: calc(12.5 * var(--base-unit, 8px));
  border: none;
  outline: none;
  font-weight: 600;
  font-family: var(--font-family);
  font-size: calc(2 * var(--base-unit, 8px));
  color: var(--foreground-light, #909090);
  height: calc(5 * var(--base-unit, 8px));
  line-height: calc(3 * var(--base-unit, 8px));
  cursor: pointer;
}
.svc-logic-paneldynamic .svc-logic-operator option {
  background: var(--background, #fff);
  color: var(--foreground, #161616);
}

.svc-logic-operator:focus {
  outline: 1px dotted var(--primary, #19b394);
}

.sl-question.svc-logic-question--answered .svc-logic-operator {
  color: var(--foreground, #161616);
}

.svc-logic-operator.svc-logic-operator:hover, .svc-logic-operator.svc-logic-operator:focus {
  color: #ffffff;
  outline: none;
  box-shadow: none;
}

.svc-logic-operator.svc-logic-operator--question {
  background-color: var(--blue-light, rgba(67, 127, 217, 0.1));
}
.svc-logic-operator.svc-logic-operator--question:hover, .svc-logic-operator.svc-logic-operator--question:focus {
  background-color: var(--blue, #437fd9);
}

.svc-logic-operator.svc-logic-operator--conjunction,
.svc-logic-operator.svc-logic-operator--operator {
  background-color: var(--yellow-light, rgba(255, 152, 20, 0.1));
}
.svc-logic-operator.svc-logic-operator--conjunction:hover, .svc-logic-operator.svc-logic-operator--conjunction:focus,
.svc-logic-operator.svc-logic-operator--operator:hover,
.svc-logic-operator.svc-logic-operator--operator:focus {
  background-color: var(--yellow, #ff9814);
}

.svc-logic-operator.svc-logic-operator--action {
  background-color: var(--red-light, rgba(229, 10, 62, 0.1));
}
.svc-logic-operator.svc-logic-operator--action:hover, .svc-logic-operator.svc-logic-operator--action:focus {
  background-color: var(--red, #e60a3e);
}

.svc-logic-operator.svc-logic-operator--error {
  background-color: var(--background, #fff);
  color: var(--red, #e60a3e);
  box-shadow: inset 0 0 0 2px var(--red, #e60a3e);
}

.svc-logic-operator__error {
  display: none;
}

.svc-logic-paneldynamic .sd-paneldynamic__separator {
  display: none;
}

.svc-action-button.svc-logic-condition-remove.svc-icon-remove:focus {
  outline: none;
}

.svc-logic-condition-remove.svc-icon-remove {
  display: none;
  width: calc(5 * var(--base-unit, 8px));
  height: calc(5 * var(--base-unit, 8px));
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 4H20H16V2C16 0.9 15.1 0 14 0H10C8.9 0 8 0.9 8 2V4H4H2V6H4V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V6H22V4ZM10 2H14V4H10V2ZM18 20H6V6H8H16H18V20ZM14 8H16V18H14V8ZM11 8H13V18H11V8ZM8 8H10V18H8V8Z' fill='%23E60A3E'/%3E%3C/svg%3E%0A");
  background-size: calc(3 * var(--base-unit, 8px)) calc(3 * var(--base-unit, 8px));
  background-position: center;
}

.sl-panel-wrapper--in-row:hover .svc-logic-condition-remove.svc-icon-remove,
.sl-panel-wrapper--in-row:focus-within .svc-logic-condition-remove.svc-icon-remove {
  display: block;
}

.svc-logic-condition-remove.svc-icon-remove:hover,
.svc-logic-condition-remove.svc-icon-remove:focus {
  background-color: var(--red-light, rgba(229, 10, 62, 0.1));
}

.svc-logic-condition-remove-question {
  height: calc(5 * var(--base-unit, 8px));
}

.svc-logic-paneldynamic__button.svc-logic-paneldynamic__remove-btn {
  display: none;
}

.svc-logic-paneldynamic__button.svc-logic-paneldynamic__remove-btn {
  display: none;
}

.svc-logic-operator--action.sl-paneldynamic__add-btn.sl-paneldynamic__add-btn,
.svc-logic-operator--operator.sl-paneldynamic__add-btn.sl-paneldynamic__add-btn {
  color: var(--foreground-light, #909090);
  margin-top: calc(2 * var(--base-unit, 8px));
}
.svc-logic-operator--action.sl-paneldynamic__add-btn.sl-paneldynamic__add-btn:hover, .svc-logic-operator--action.sl-paneldynamic__add-btn.sl-paneldynamic__add-btn:focus,
.svc-logic-operator--operator.sl-paneldynamic__add-btn.sl-paneldynamic__add-btn:hover,
.svc-logic-operator--operator.sl-paneldynamic__add-btn.sl-paneldynamic__add-btn:focus {
  color: var(--primary-foreground, #fff);
}

.svc-logic-tab__content.svc-logic-tab__empty {
  flex-direction: column;
  display: flex;
  align-items: center;
  position: absolute;
  top: 35%;
}
.svc-logic-tab__content.svc-logic-tab__empty .svc-logic-tab__content-action {
  width: calc(33 * var(--base-unit, 8px));
  margin-top: calc(4 * var(--base-unit, 8px));
}
.svc-logic-tab__content .svc-logic-tab__content-action {
  margin-left: 25%;
  margin-right: 25%;
  margin-bottom: calc(8 * var(--base-unit, 8px));
}
.svc-logic-tab__content .svc-logic-tab__content-action--disabled {
  cursor: default;
  outline: solid calc(0.25 * var(--base-unit, 8px)) transparent;
}
.svc-logic-tab__content .svc-logic-tab__content-action--disabled:focus, .svc-logic-tab__content .svc-logic-tab__content-action--disabled:hover {
  background-color: var(--background, #fff);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
}
.svc-logic-tab__content .svc-logic-tab__content-action--disabled .svc-text {
  color: var(--foreground, #161616);
  opacity: 0.25;
}
.svc-logic-tab__content .sd-paneldynamic__panel-wrapper--in-row {
  margin-top: 0;
  align-items: start;
}

.svc-logic-paneldynamic {
  font-family: var(--font-family);
}
.svc-logic-paneldynamic .sd-row--multiple {
  padding: 0;
  background: none;
  box-shadow: none;
  border-radius: 0;
  overflow: visible;
}
.svc-logic-paneldynamic .sd-question__content {
  margin-top: 0;
}
.svc-logic-paneldynamic .sd-row__panel {
  width: calc(100% - 5 * var(--base-unit, 8px));
}

.svc-logic-tab__content .svc-logic-paneldynamic .sd-row {
  margin-top: 0;
  flex-flow: row wrap;
}

.sl-table__cell--detail-panel .sd-body {
  margin: 0;
  min-width: none;
  max-width: none;
}
.sl-table__cell--detail-panel .sd-page.sd-body__page {
  padding: 0;
}
.sl-table__cell--detail-panel .sl-panel__footer {
  margin: 0;
}

.sl-dropdown {
  max-width: 45vw;
}

.sl-dropdown__value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.svc-logic-placeholder {
  width: calc(78 * var(--base-unit, 8px));
  min-width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin-top: calc(2 * var(--base-unit, 8px));
  background: var(--background, #fff);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: calc(0.5 * var(--base-unit, 8px));
  padding: calc(4 * var(--base-unit, 8px)) calc(5 * var(--base-unit, 8px)) calc(5 * var(--base-unit, 8px));
}

.svc-logic-placeholder__text {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: calc(2 * var(--base-unit, 8px));
  line-height: calc(3 * var(--base-unit, 8px));
  text-align: center;
  color: var(--foreground-light, #909090);
  display: block;
  padding: calc(4 * var(--base-unit, 8px)) calc(8 * var(--base-unit, 8px));
}

.svc-logic-tab__leave-apply-button {
  background-color: var(--red, #e60a3e);
}