.spg-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  background-color: $background;
  box-sizing: border-box;
  width: 100%;
  height: calcSize(6);
  border: none;
  box-shadow: inset 0 0 0 1px $border-inside;
  padding: calcSize(1.5) calcSize(2);
  outline: none;
  font-size: calcSize(2);
  font-family: $font-family;
  color: $foreground;
}

.spg-input::placeholder {
  color: $foreground-light;
}

.spg-input:focus,
.spg-input.spg-dropdown:focus,
.spg-input.spg-dropdown:focus-within,
.spg-input-container:focus-within {
  box-shadow: inset 0 0 0 2px $primary;
}

.spg-input:disabled,
.spg-input:disabled::placeholder {
  color: $foreground;
  opacity: 0.25;
}

.spg-input[type="color"] {
  padding-left: 0;
  padding-right: 0;
  min-width: calcSize(8);
}
.spg-input__edit-button {
  position: relative;
  box-sizing: border-box;
  appearance: none;
  background: $background;
  border: none;
  outline: none;
  width: calcSize(5);
  height: calcSize(5);
  padding: calcSize(1);
  line-height: 0;
  &:hover {
    background: $background-dim;
  }
  &:active {
    opacity: 0.5;
  }
  &:disabled {
    opacity: 0.25;
    background: $background;
  }
  svg {
    height: 24px;
    width: 24px;
    fill: rgba(0, 0, 0, 0.45);
  }
}
.spg-input.spg-input--error {
  box-shadow: 0 0 0 1px inset $red;
}
.spg-input-container {
  display: flex;
  justify-content: space-between;
  cursor: default;
  padding: calcSize(0.5);
  align-items: center;
  gap: calcSize(0.5);
  box-shadow: 0 0 0 1px inset $border-inside;
}
.spg-input-container__input {
  flex-grow: 1;
  width: 100%;
  padding: calcSize(1) calcSize(1.5);
  color: $foreground;
  font-size: calcSize(2);
  font-family: $font-family;
  outline: none;
  border: none;
  line-height: calcSize(3);
  background-color: transparent;
}
.spg-input-container__buttons-container {
  display: flex;
  gap: calcSize(0.5);
}
