.svc-image-item-value-wrapper {
  display: inline-block;
  position: relative;
}

.svc-image-item-value--new {
  display: none;
  height: max-content;
}
.svc-image-item-value--new .sd-imagepicker__image {
  box-sizing: border-box;
  border: 1px dashed var(--border, #d6d6d6);
}

.svc-image-item-value--file-dragging .sd-imagepicker__image {
  border: 2px solid var(--primary, #19b394);
  background-color: var(--primary-light, rgba(25, 179, 148, 0.1));
}
.svc-image-item-value--file-dragging .svc-image-item-value__item {
  position: relative;
  z-index: 2;
}

.svc-question__content--selected .svc-image-item-value--new {
  display: inline-block;
}

.svc-image-item-value-controls {
  position: absolute;
  top: calc(1 * var(--base-unit, 8px));
  right: calc(1 * var(--base-unit, 8px));
}

.svc-image-item-value--new .svc-image-item-value-controls {
  top: calc(50% - 3 * var(--base-unit, 8px));
  left: calc(50% - 3 * var(--base-unit, 8px));
}

.svc-image-item-value--new .svc-image-item-value-controls__button {
  background-color: transparent;
}

.svc-image-item-value-controls__add use {
  fill: var(--primary, #19b394);
}

.svc-image-item-value-controls__add:hover, .svc-image-item-value-controls__add:focus {
  background-color: var(--primary-light, rgba(25, 179, 148, 0.1));
}

.svc-choose-file-input {
  position: absolute;
  opacity: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.svc-image-item-value__item .sd-imagepicker__item.sd-imagepicker__item {
  width: 100%;
}

.svc-image-item-value-controls__drag-area-indicator {
  display: none;
  cursor: move;
  position: absolute;
  top: calc(1 * var(--base-unit, 8px));
  left: calc(1 * var(--base-unit, 8px));
}

.svc-question__content--selected .svc-image-item-value-wrapper:hover .svc-image-item-value-controls__drag-area-indicator {
  display: block;
}

.svc-image-item-value-wrapper--ghost .svc-image-item-value-wrapper__ghost {
  display: block;
}
.svc-image-item-value-wrapper--ghost .svc-image-item-value-wrapper__content {
  display: none;
}

.svc-image-item-value-wrapper__ghost {
  display: none;
  background: var(--background-dim, #f3f3f3);
}

.svc-image-item-value--single {
  width: 100%;
}
.svc-image-item-value--single .sd-imagepicker__image {
  height: calc(30 * var(--base-unit, 8px));
}