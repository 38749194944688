svc-tab-json-editor-textarea {
  width: 100%;
  height: 100%;
  background: var(--background-dim, #f3f3f3);
}

.svc-json-editor-tab__content {
  position: relative;
  height: 100%;
}

.svc-json-editor-tab__content-area {
  width: 100%;
  height: 100%;
}

.svc-json-editor-tab__errros_button {
  position: absolute;
  top: 0%;
  right: calc(0% + (2 * var(--base-unit, 8px)));
  min-height: calc(2.5 * var(--base-unit, 8px));
  max-height: calc(2.5 * var(--base-unit, 8px));
  border: none;
  background-color: var(--primary, #19b394);
  color: var(--primary-foreground, #fff);
  font-weight: bold;
}

.svc-json-editor-tab__content-errors {
  position: absolute;
  right: 0%;
  top: calc(0% + (3 * var(--base-unit, 8px)));
  max-width: calc(100% - (2 * var(--base-unit, 8px)));
  padding: var(--base-unit, 8px);
  background-color: var(--background, #fff);
  background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.1));
  border: 1px solid var(--border, #d6d6d6);
  border-radius: 1px;
  color: var(--foreground, #161616);
  font-family: monospace;
}
.svc-json-editor-tab__content-errors span {
  white-space: pre-line;
}
.svc-json-editor-tab__content-errors span > b {
  color: var(--red, #e60a3e);
}

.svc-json-editor-tab__content-errors:empty {
  display: none;
}