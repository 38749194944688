.svc-side-bar__container {
  display: block;
  position: relative;
  font-family: var(--font-family);
  min-width: 370px;
  width: 450px;
  height: 100%;
}

.svc-side-bar__container .svc-resizer {
  width: 3px;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: transparent;
  cursor: e-resize;
}

.svc-resizer-west {
  left: 0;
}

.svc-resizer-east {
  right: 0;
}

.svc-side-bar__container-header {
  background: var(--background, #fff);
  box-shadow: inset 0px -2px 0px var(--primary, #19b394);
  line-height: calc(3 * var(--base-unit, 8px));
  display: flex;
}

.svc-side-bar__container-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: calc(100% - (8 * var(--base-unit, 8px)));
  overflow-y: auto;
  background-color: var(--background, #fff);
}

.svc-side-bar__container-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0px calc(2 * var(--base-unit, 8px)) calc(3 * var(--base-unit, 8px));
  box-sizing: border-box;
  background: var(--background-dim, #f3f3f3);
  box-shadow: inset 0px -1px 0px var(--border, #d6d6d6);
}

.svc-side-bar__container-title {
  display: flex;
  line-height: calc(4.5 * var(--base-unit, 8px));
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: flex-end;
  padding: calc(1.5 * var(--base-unit, 8px)) calc(2 * var(--base-unit, 8px));
}

.svc-side-bar__container-actions {
  width: 100%;
  padding: calc(1.5 * var(--base-unit, 8px)) calc(1 * var(--base-unit, 8px));
}
.svc-side-bar__container-actions .sv-action-bar {
  justify-content: flex-end;
  padding: 0;
}

.svc-side-bar__container-close {
  display: none;
}

.svc-flex-column.svc-side-bar__wrapper {
  width: auto;
  border-left: 1px solid var(--border, #d6d6d6);
  height: 100%;
  background-color: var(--background-dim, #f3f3f3);
}

.svc-side-bar {
  height: 100%;
}

.svc-flyout-side-bar {
  width: 0;
  overflow: visible;
}
.svc-flyout-side-bar .svc-side-bar__shadow {
  background-color: var(--foreground-light, #909090);
  opacity: 0.75;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9000;
}
.svc-flyout-side-bar .svc-side-bar__wrapper {
  border-left: none;
  z-index: 10000;
  position: absolute;
  right: 0;
  display: inline-block;
  height: 100%;
  max-width: 100%;
}

.svc-full-container.svc-creator__side-bar--left {
  flex-direction: row-reverse;
}
.svc-full-container.svc-creator__side-bar--left .svc-side-bar__wrapper {
  left: 0;
  right: unset;
}
.svc-full-container.svc-creator__side-bar--left .svc-side-bar__container-header .sv-action-bar {
  flex-direction: row-reverse;
}
.svc-full-container.svc-creator__side-bar--left .sv-action--object-selector {
  justify-content: flex-start;
  flex: 1;
  display: flex;
}
.svc-full-container.svc-creator__side-bar--left .svd-grid-hide .sv-svg-icon {
  transform: rotate(180deg);
}

.svd-grid-hide {
  margin-inline-end: auto;
}

.sv-mobile-side-bar .svc-side-bar__container-actions {
  display: none;
}
.sv-mobile-side-bar .svc-side-bar__container-title {
  display: block;
  font-weight: bold;
  font-size: calc(3 * var(--base-unit, 8px));
  line-height: calc(4 * var(--base-unit, 8px));
  flex: unset;
}
.sv-mobile-side-bar .svc-side-bar__wrapper {
  top: calc(2 * var(--base-unit, 8px));
  background: none;
}
.sv-mobile-side-bar .svc-side-bar__container {
  width: 100%;
  min-width: 338px;
}
.sv-mobile-side-bar .svc-side-bar__container-header {
  border: unset;
  box-shadow: 0px calc(1 * var(--base-unit, 8px)) calc(2 * var(--base-unit, 8px));
  border-radius: calc(2 * var(--base-unit, 8px)) calc(2 * var(--base-unit, 8px)) 0px 0px;
}
.sv-mobile-side-bar .svc-side-bar__container-content {
  height: calc(100% - 20 * var(--base-unit, 8px));
  background-color: var(--background-dim, #f3f3f3);
}
.sv-mobile-side-bar .svc-side-bar__container-close {
  display: flex;
  background-color: var(--background-dim, #f3f3f3);
}
.sv-mobile-side-bar .svc-side-bar__container-close .svc-side-bar__container-close-button {
  width: 100%;
  margin: calc(2 * var(--base-unit, 8px));
}

.svc-side-bar .svc-toolbox {
  width: 100%;
}