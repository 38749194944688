.svc-text {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: normal;
  color: var(--foreground, #161616);
}

.svc-text--normal {
  font-size: calc(2 * var(--base-unit, 8px));
  line-height: calc(3 * var(--base-unit, 8px));
}

.svc-text--small {
  font-size: calc(1.5 * var(--base-unit, 8px));
  line-height: calc(2 * var(--base-unit, 8px));
}

.svc-text--bold {
  font-weight: 600;
}