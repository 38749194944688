.svc-image-question-controls {
  position: absolute;
  top: calc(5 * var(--base-unit, 8px));
  right: calc(1 * var(--base-unit, 8px));
  display: none;
}

.svc-question__content--selected .svc-image-question-controls {
  display: flex;
}

.svc-question__content--image:not(.svc-question__content--empty) {
  padding: calc(4 * var(--base-unit, 8px)) 0 calc(8 * var(--base-unit, 8px)) 0;
}

.svc-question__content--image.svc-question__content--empty .sd-file,
.svc-question__content--image.svc-question__content--empty sv-ng-file-question {
  width: 100%;
}

.svc-question__content--image.svc-question__content--empty .sd-question--image {
  display: none;
}