.svc-tabbed-menu {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  align-items: center;
  height: calc(8 * var(--base-unit, 8px));
}

.svc-tabbed-menu-wrapper {
  flex: 1 1 auto;
  overflow: hidden;
}