.svc-item-value-wrapper {
  position: relative;
  display: flex;
  gap: calc(1 * var(--base-unit, 8px));
  align-items: center;
  margin-left: calc(-5 * var(--base-unit, 8px));
}

.svc-question__dropdown-choice .svc-item-value-wrapper,
.sd-selectbase .svc-item-value-wrapper {
  align-items: flex-start;
  min-width: calc(28 * var(--base-unit, 8px));
}
.svc-question__dropdown-choice .svc-item-value__item,
.sd-selectbase .svc-item-value__item {
  padding-right: calc(1 * var(--base-unit, 8px));
}

.svc-question__content .sd-selectbase__column:not(.sd-imagepicker__column):not(:first-of-type) {
  margin-left: calc(5 * var(--base-unit, 8px));
}
.svc-question__content .sd-selectbase__column:not(:last-child) {
  padding-right: 0;
}

.svc-item-value--dragging {
  background: var(--background, #fff);
  box-shadow: 0px calc(1 * var(--base-unit, 8px)) calc(2 * var(--base-unit, 8px)) rgba(0, 0, 0, 0.1);
  border-radius: calc(12.5 * var(--base-unit, 8px));
  padding-right: calc(3 * var(--base-unit, 8px));
  padding-left: calc(0.5 * var(--base-unit, 8px));
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.svc-item-value-controls {
  display: flex;
  margin: calc(1 * var(--base-unit, 8px)) 0;
  height: calc(4 * var(--base-unit, 8px));
  box-sizing: border-box;
  flex-shrink: 0;
  min-width: 72px;
  justify-content: flex-end;
}

.svc-item-value-controls__button {
  border-radius: 50%;
  padding: calc(1 * var(--base-unit, 8px));
  cursor: pointer;
  display: inline-block;
  outline: none;
  height: calc(2 * var(--base-unit, 8px));
  box-sizing: content-box;
}
.svc-item-value-controls__button .sv-svg-icon {
  display: block;
}

.svc-item-value-controls__button > span {
  display: block;
}

.svc-item-value-controls__drag-icon {
  display: block;
}

.svc-item-value-wrapper:hover:not(.svc-item-value--ghost) .svc-item-value-controls__drag-icon,
.svc-item-value-controls__drag:hover:not(.svc-item-value--ghost) .svc-item-value-controls__drag-icon {
  visibility: visible;
}

.svc-item-value-controls__drag {
  padding: calc(0.5 * var(--base-unit, 8px)) calc(1 * var(--base-unit, 8px));
  cursor: move;
  opacity: 0.25;
}
.svc-item-value-controls__drag .svc-item-value-controls__drag-icon {
  visibility: hidden;
}
.svc-item-value-controls__drag use {
  fill: var(--foreground, #161616);
}

.svc-item-value-controls__button--disabled {
  fill: var(--foreground-light, #909090);
  opacity: 0.25;
  cursor: default;
}

.svc-item-value-controls__remove:not(.svc-item-value-controls__button--disabled) use {
  fill: var(--red, #e60a3e);
}

.svc-item-value-controls__add:not(.svc-item-value-controls__button--disabled) use {
  fill: var(--primary, #19b394);
}

.svc-item-value-controls__add:not(.svc-item-value-controls__button--disabled):hover, .svc-item-value-controls__add:not(.svc-item-value-controls__button--disabled):focus {
  background-color: var(--primary-light, rgba(25, 179, 148, 0.1));
}

.svc-item-value-controls__remove:not(.svc-item-value-controls__button--disabled):hover, .svc-item-value-controls__remove:not(.svc-item-value-controls__button--disabled):focus {
  background-color: var(--red-light, rgba(229, 10, 62, 0.1));
}

.sv-string-editor {
  cursor: text;
}

.svc-item-value--new .svc-item-value__item .sd-item__control-label,
.svc-item-value--new .svc-item-value__item .sv-ranking-item__text {
  color: var(--foreground-light, #909090);
}
.svc-item-value--new .svc-item-value__item .sd-item__decorator {
  opacity: 0.35;
}
.svc-item-value--new .sv-ranking-item__index {
  background: var(--background-dim, #f3f3f3);
}
.svc-item-value--new .sv-ranking-item__index.sv-ranking-item__index--empty:empty {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAACCAYAAABhYU3QAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAaSURBVHgBjcABDQAACAKwa2X6b1Tghxi8UQEkAAB3NR3N2gAAAABJRU5ErkJggg==);
}

.sv-ranking .svc-item-value-wrapper {
  align-items: center;
  min-width: calc(27 * var(--base-unit, 8px));
}
.sv-ranking .svc-item-value__item {
  padding-right: 0;
}

.svc-item-value__item .sv-ranking-item__content {
  padding-left: 0;
}
.svc-item-value__item .sv-ranking-item__icon-container {
  display: none;
}
.svc-item-value__item .sv-ranking-item__text {
  overflow: visible;
}

.svc-item-value--ghost .svc-item-value__ghost {
  display: block;
}
.svc-item-value--ghost .svc-item-value-controls {
  visibility: hidden;
}

.svc-item-value--movedown {
  transform: translate(0, 0);
  animation: svdragdropmovedown 0.1s;
  animation-timing-function: ease-in-out;
}

@keyframes svdragdropmovedown {
  0% {
    transform: translate(0, -50px);
  }
  100% {
    transform: translate(0, 0);
  }
}
.svc-item-value--moveup {
  transform: translate(0, 0);
  animation: svdragdropmoveup 0.1s;
  animation-timing-function: ease-in-out;
}

@keyframes svdragdropmoveup {
  0% {
    transform: translate(0, 50px);
  }
  100% {
    transform: translate(0, 0);
  }
}
.svc-item-value__ghost {
  display: none;
  background: var(--background-dim, #f3f3f3);
  border-radius: calc(12.5 * var(--base-unit, 8px));
  width: calc(25 * var(--base-unit, 8px));
  height: 40px;
  z-index: 11;
  position: absolute;
  left: 35px;
}