.svc-creator--mobile .sd-root-modern {
  --sd-base-padding: calc(3 * var(--base-unit, 8px));
  --sd-base-vertical-padding: calc(2 * var(--base-unit, 8px));
  --sd-page-vertical-padding: calc(2 * var(--base-unit, 8px));
}
.svc-creator--mobile .svc-question__content .sd-question__content .sd-selectbase__column {
  max-width: initial;
  display: block;
  margin-left: 0;
}
.svc-creator--mobile .svc-question__content--selected .svc-rating-question-controls {
  bottom: calc(10 * var(--base-unit, 8px));
}
.svc-creator--mobile .svc-page {
  margin-top: calc(1 * var(--base-unit, 8px));
}
.svc-creator--mobile .svc-page__content {
  padding-left: 0;
  padding-right: 0;
  border: 0;
}
.svc-creator--mobile .svc-page__content .sd-page {
  padding: calc(1 * var(--base-unit, 8px)) calc(2 * var(--base-unit, 8px)) calc(2 * var(--base-unit, 8px));
}
.svc-creator--mobile .svc-page__content .svc-page__footer {
  margin-left: calc(2 * var(--base-unit, 8px));
  margin-right: calc(2 * var(--base-unit, 8px));
}
.svc-creator--mobile .svc-question__content {
  padding: calc(2 * var(--base-unit, 8px)) calc(3 * var(--base-unit, 8px)) calc(3 * var(--base-unit, 8px));
}
.svc-creator--mobile .svc-question__content.svc-question__content--selected {
  padding-bottom: calc(8 * var(--base-unit, 8px));
}
.svc-creator--mobile .svc-tab-designer {
  justify-content: initial;
}
.svc-creator--mobile .svc-tab-designer .sd-title.sd-container-modern__title {
  padding: calc(2 * var(--base-unit, 8px));
  flex-direction: column-reverse;
  align-items: flex-start;
}
.svc-creator--mobile .svc-tab-designer .sd-title.sd-container-modern__title .svc-logo-image {
  margin-top: 0;
}
.svc-creator--mobile .svc-tab-designer .sd-title.sd-container-modern__title .svc-logo-image-placeholder {
  margin-left: calc(-2 * var(--base-unit, 8px));
}
.svc-creator--mobile .svc-tab-designer .sd-title.sd-container-modern__title h3 {
  margin: 0;
  font-size: calc(3 * var(--base-unit, 8px));
  line-height: calc(4 * var(--base-unit, 8px));
}
.svc-creator--mobile .svc-tab-designer .sd-title.sd-container-modern__title h5 {
  margin: 0;
}
.svc-creator--mobile .svc-tab-designer .sd-container-modern {
  min-width: calc(46 * var(--base-unit, 8px));
}
.svc-creator--mobile .svc-properties-wrapper {
  width: 100%;
}
.svc-creator--mobile .svc-question__content-actions {
  width: calc(100% - 3 * var(--base-unit, 8px));
  left: calc(2 * var(--base-unit, 8px));
}
.svc-creator--mobile .svc-question__content-actions .sv-action-bar-item {
  border: 0;
}
.svc-creator--mobile .svc-page__content-actions .sv-action-bar {
  padding-right: calc(1 * var(--base-unit, 8px));
}
.svc-creator--mobile .svc-page__content-actions .sv-action-bar .sv-action .sv-action__content {
  padding-right: 0;
}
.svc-creator--mobile .svc-page__content-actions .sv-action-bar .sv-action .sv-action__content .sv-action-bar-item__title--with-icon {
  display: none;
}