svc-tab-test {
  width: 100%;
  height: 100%;
  background: var(--background-dim, #f3f3f3);
}

.svc-test-tab__content .svc-plugin-tab__content {
  overflow-y: overlay;
}
.svc-test-tab__content .svc-plugin-tab__content .sv-root-modern .sv-completedpage,
.svc-test-tab__content .svc-plugin-tab__content .sv_default_css .sv_completed_page {
  margin: 0;
  border: 0;
  background-color: var(--background-dim, #f3f3f3);
}
.svc-test-tab__content .svc-plugin-tab__content .sv_default_css .sv_body {
  border: 0;
}
.svc-test-tab__content .svc-plugin-tab__content .svc-preview__test-again {
  width: calc(33 * var(--base-unit, 8px));
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 72px;
  margin-top: 8px;
}

.svc-creator-tab__content--with-toolbar.svc-test-tab__content .svc-plugin-tab__content {
  height: calc(100% - (6 * var(--base-unit, 8px)));
}

.svc-test-tab__content-actions {
  position: relative;
}
.svc-test-tab__content-actions .sv-action-bar {
  padding: 0;
  height: calc(6 * var(--base-unit, 8px));
  background: var(--background, #fff);
  border-top: 1px solid var(--border, #d6d6d6);
  width: 100%;
  position: absolute;
}
.svc-test-tab__content-actions .sv-action-bar.sv-action-bar--pages {
  left: 0;
  right: 0;
  justify-content: center;
  gap: calc(1 * var(--base-unit, 8px));
}
.svc-test-tab__content-actions .sv-action-bar.sv-action-bar--pages .sv-action__content {
  padding: 0;
}
.svc-test-tab__content-actions .sv-action-bar.sv-action-bar--pages .sv-action-bar-item {
  margin: 0;
}
.svc-test-tab__content-actions .sv-action-bar-item {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 600;
  font-size: calc(1.5 * var(--base-unit, 8px));
  line-height: calc(2 * var(--base-unit, 8px));
  width: 100%;
  height: calc(4 * var(--base-unit, 8px));
}
.svc-test-tab__content-actions .svc-page-selector {
  max-width: 50%;
}
.svc-test-tab__content-actions .svc-page-selector .sv-action-bar-item__title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--foreground, #161616);
  display: inline-block;
}

.svc-test-tab__content .sd-body--empty {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: calc(2 * var(--base-unit, 8px));
  color: var(--foreground, #161616);
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  overflow: hidden;
}