.svc-tab-translation {
  width: 100%;
  height: 100%;
  background-color: var(--background-dim, #f3f3f3);
}

.st-properties .spg-item.spg-selectbase__label:focus-within {
  outline: 1px dotted var(--primary, #19b394);
}
.st-properties .spg-selectbase__label:focus-within {
  outline: none;
}
.st-properties .spg-panel__content .spg-row {
  margin-top: 0;
}

.st-no-strings {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: calc(2 * var(--base-unit, 8px));
  color: var(--foreground, #161616);
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  overflow: hidden;
}

.st-content {
  display: flex;
  height: 100%;
}

.st-strings {
  height: calc(100% - (6 * var(--base-unit, 8px)));
  overflow-y: auto;
  overflow-x: hidden;
}

.st-strings-wrapper {
  flex-grow: 1;
}

.st-property-panel {
  flex-shrink: 0;
  flex-grow: 0;
  width: 450px;
  border-left: 1px solid var(--border, #d6d6d6);
}

.st-property-panel .spg-panel__content .spg-row {
  margin-top: 0;
}

.st-property-panel .spg-panel__content .spg-row:first-child {
  margin-top: calc(2 * var(--base-unit, 8px));
}

.st-strings-header .st-table__cell {
  background-color: var(--background-dim, #f3f3f3);
  height: auto;
}

.st-root-modern {
  width: 100%;
}

.st-title.st-panel__title,
.st-table__cell.st-table__cell--header {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 600;
  font-size: calc(1.5 * var(--base-unit, 8px));
  line-height: calc(2 * var(--base-unit, 8px));
  color: var(--foreground-light, #909090);
  text-align: left;
}

.st-title.st-panel__title {
  height: calc(4 * var(--base-unit, 8px));
  line-height: calc(4 * var(--base-unit, 8px));
  padding-left: calc(3 * var(--base-unit, 8px));
  margin: 0;
  background-color: var(--background-for-editors, #f9f9f9);
  border-bottom: 1px solid var(--border-light, #eaeaea);
}

.st-table tr {
  display: flex;
  align-items: stretch;
}

.st-table__cell {
  display: block;
  flex: 1 1;
  box-sizing: border-box;
  background-color: var(--background, #fff);
  border-bottom: 1px solid var(--border-light, #eaeaea);
  padding: calc(1 * var(--base-unit, 8px)) 0;
}

td.st-table__cell:first-of-type {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 600;
  font-size: calc(2 * var(--base-unit, 8px));
  line-height: calc(3 * var(--base-unit, 8px));
  color: var(--foreground, #161616);
  max-width: 300px;
  padding-right: calc(3 * var(--base-unit, 8px));
}
td.st-table__cell:first-of-type span {
  display: inline-block;
  padding-left: calc(3 * var(--base-unit, 8px));
}

.st-panel-indent .st-table__cell:first-of-type span {
  padding-left: calc(6 * var(--base-unit, 8px));
}

.st-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
}

.st-comment {
  font-family: var(--font-family);
  font-style: normal;
  font-size: calc(2 * var(--base-unit, 8px));
  line-height: calc(3 * var(--base-unit, 8px));
  display: block;
  width: calc(100% - 3 * var(--base-unit, 8px));
  border: unset;
  outline: none;
  background-color: var(--background, #fff);
  color: var(--foreground, #161616);
  resize: none;
  padding: 0;
}

.sd-translation-line-skeleton {
  min-height: calc(5 * var(--base-unit, 8px));
  background-color: var(--background-dim, #f3f3f3);
}