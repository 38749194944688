.svc-context-container {
  display: flex;
  gap: calc(1 * var(--base-unit, 8px));
  width: max-content;
}

.svc-context-button {
  display: block;
  box-sizing: border-box;
  background-color: var(--background, #fff);
  border-radius: 50%;
  width: calc(6 * var(--base-unit, 8px));
  height: calc(6 * var(--base-unit, 8px));
  cursor: pointer;
  padding: calc(1.5 * var(--base-unit, 8px));
  outline: none;
}
.svc-context-button use {
  fill: var(--foreground-light, #909090);
}

.svc-context-button:hover use, .svc-context-button:focus use {
  fill: var(--primary, #19b394);
}

.svc-context-button--danger:hover use, .svc-context-button--danger:focus use {
  fill: var(--red, #e60a3e);
}