.svc-matrix-cell {
  position: relative;
}

.sd-table thead tr th .svc-matrix-cell {
  padding: calc(1.5 * var(--base-unit, 8px));
}

.svc-matrix-cell--selected {
  position: absolute;
  border: calc(0.25 * var(--base-unit, 8px)) solid var(--secondary, #ff9814);
  border-radius: calc(0.5 * var(--base-unit, 8px));
  top: calc(0 * var(--base-unit, 8px));
  left: calc(0 * var(--base-unit, 8px));
  right: calc(0 * var(--base-unit, 8px));
  bottom: calc(0 * var(--base-unit, 8px));
  display: none;
}
.svc-matrix-cell--selected.svc-visible {
  display: block;
}

.svc-hovered > .svc-matrix-cell--selected:not(.svc-visible) {
  border: calc(0.25 * var(--base-unit, 8px)) solid var(--secondary-light, rgba(255, 152, 20, 0.25));
  border-radius: calc(0.5 * var(--base-unit, 8px));
  display: block;
}

.svc-matrix-cell__question-controls {
  display: none;
  position: absolute;
  top: calc(50% - 3 * var(--base-unit, 8px));
  left: calc(50% - 3 * var(--base-unit, 8px));
  z-index: 2;
}

.svc-matrix-cell__question-controls-button {
  display: block;
  width: calc(6 * var(--base-unit, 8px));
  height: calc(6 * var(--base-unit, 8px));
  background: var(--background, #fff);
  border: 1px solid var(--border, #d6d6d6);
  box-sizing: border-box;
  border-radius: 50%;
  padding: calc(1.5 * var(--base-unit, 8px));
  cursor: pointer;
}
.svc-matrix-cell__question-controls-button use {
  fill: var(--foreground-light, #909090);
}

.svc-matrix-cell:hover .svc-matrix-cell__question-controls {
  display: block;
}

.svc-question__content--in-popup {
  width: calc(100% - 25 * var(--base-unit, 8px));
  min-width: calc(70 * var(--base-unit, 8px));
  padding: calc(5 * var(--base-unit, 8px));
}

.svc-matrix-cell__popup .sv-popup__scrolling-content {
  margin-top: calc(1 * var(--base-unit, 8px));
  padding-top: 0;
}
.svc-matrix-cell__popup .svc-question__content {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.svc-question__content .sd-table__cell--detail-panel .svc-question__adorner {
  z-index: 13;
}