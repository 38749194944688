svc-tab-json-editor-ace {
  width: 100%;
  height: 100%;
  background: var(--background-dim, #f3f3f3);
}

.svc-json-editor-tab__content {
  position: relative;
  height: 100%;
}

.svc-json-editor-tab__ace-editor {
  height: 100%;
}