.svc-flex-container {
  display: flex;
}

.svc-flex-row {
  display: flex;
  flex-direction: row;
  background: var(--background-dim, #f3f3f3);
}

.svc-full-container {
  height: 100%;
  width: 100%;
}

.svc-flex-row__element--growing {
  flex: 1;
  overflow: auto;
}

.svc-flex-column {
  display: flex;
  flex-direction: column;
}

.sv-action-bar--default-size-mode .sv-action-bar-separator {
  height: calc(5 * var(--base-unit, 8px));
}

.sv-action-bar--small-size-mode .sv-action-bar-separator {
  height: calc(4 * var(--base-unit, 8px));
}

.svc-creator--mobile .sv-action-bar-separator {
  height: calc(3 * var(--base-unit, 8px));
}

.sv-action-bar--small-size-mode .sv-action-bar-item {
  font-weight: 600;
  align-items: center;
}

.svc-top-bar {
  display: flex;
  background: var(--background, #fff);
  box-sizing: border-box;
  box-shadow: inset 0px -1px 0px var(--border, #d6d6d6);
}
.svc-top-bar .svc-toolbar-wrapper {
  flex: 0 0 auto;
  display: flex;
}
.svc-top-bar .svc-toolbar-wrapper .sv-action-bar {
  padding: 0 calc(1 * var(--base-unit, 8px));
  justify-content: flex-end;
}
.svc-top-bar .svc-toolbar-wrapper .sv-action .sv-action-bar-item {
  margin: 0 calc(1 * var(--base-unit, 8px));
}

.svc-footer-bar .svc-toolbar-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: calc(6 * var(--base-unit, 8px));
}
.svc-footer-bar .svc-toolbar-wrapper .sv-action-bar {
  justify-content: center;
  padding: 0;
  width: 100%;
  height: calc(6 * var(--base-unit, 8px));
  background: var(--background, #fff);
  border-top: 1px solid var(--border, #d6d6d6);
}