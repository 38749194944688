svc-toolbox {
  display: flex;
  flex-direction: column;
  position: absolute;
}

.svc-toolbox {
  height: 100%;
  padding: calc(2 * var(--base-unit, 8px));
  box-sizing: border-box;
  overflow-y: auto;
  direction: rtl;
}

.svc-toolbox--compact {
  width: calc(10.5 * var(--base-unit, 8px));
  overflow: visible;
  direction: ltr;
}

.svc-toolbox__container {
  direction: ltr;
}

.svc-toolbox__category {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.svc-toolbox__tool:not(.sv-dots) {
  display: flex;
  width: auto;
}

.svc-toolbox__tool .sv-dots__item {
  width: calc(6 * var(--base-unit, 8px));
  margin-top: calc(0.5 * var(--base-unit, 8px));
}

.svc-toolbox__category-separator {
  height: 1px;
  width: calc(6 * var(--base-unit, 8px));
  background-color: var(--border, #d6d6d6);
  margin: calc(1 * var(--base-unit, 8px)) 0 calc(1.5 * var(--base-unit, 8px));
}

.svc-toolbox__category-header {
  height: calc(5 * var(--base-unit, 8px));
  padding: calc(1.5 * var(--base-unit, 8px));
  background: var(--background-dim, #f3f3f3);
  border-bottom: 1px solid var(--border, #d6d6d6);
  box-sizing: border-box;
  width: 100%;
  min-width: calc(20 * var(--base-unit, 8px));
  position: relative;
}

.svc-toolbox__category-header--collapsed {
  cursor: pointer;
}

.svc-toolbox__category-title {
  font-family: var(--font-family);
  font-size: calc(1.5 * var(--base-unit, 8px));
  font-weight: bold;
  line-height: calc(2 * var(--base-unit, 8px));
  color: var(--foreground, #161616);
  vertical-align: middle;
  display: block;
}

.svc-toolbox__category-header__controls {
  position: absolute;
  right: calc(1 * var(--base-unit, 8px));
  top: calc(1 * var(--base-unit, 8px));
  display: none;
}

.svc-toolbox__category-header__button {
  fill: var(--foreground-light, #909090);
}

.svc-toolbox__category-header:hover .svc-toolbox__category-header__controls {
  display: block;
}

.svc-toolbox__tool {
  cursor: default;
}
.svc-toolbox__tool .sv-action__content {
  display: flex;
  padding-top: calc(0.5 * var(--base-unit, 8px));
  flex-direction: column;
  align-items: flex-start;
}

.svc-creator__toolbox--right .svc-toolbox__tool .sv-action__content {
  align-items: flex-end;
}

.svc-toolbox__category--collapsed .svc-toolbox__tool {
  height: 0;
  visibility: hidden;
}