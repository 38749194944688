.svc-embed-tab__content {
  padding: calc(2 * var(--base-unit, 8px));
  font-family: var(--font-family);
}
.svc-embed-tab__content .spg-row {
  display: flex;
}
.svc-embed-tab__content .spg-row .spg-question__content .spg-comment:disabled,
.svc-embed-tab__content .spg-row .spg-question__content .spg-comment:disabled::placeholder {
  color: var(--foreground, #161616);
}
.svc-embed-tab__content .spg-question__description {
  line-height: calc(3 * var(--base-unit, 8px));
  font-size: calc(2 * var(--base-unit, 8px));
  color: var(--foreground, #161616);
  padding: calc(2 * var(--base-unit, 8px)) 0 calc(2 * var(--base-unit, 8px)) calc(2 * var(--base-unit, 8px));
}

.sv-question-embed__title {
  display: none;
}

.spg-question__nopadding .spg-panel__content {
  padding: 0px;
  box-shadow: unset;
}