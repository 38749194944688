.svc-tabbed-menu-item {
  padding: calc(2 * var(--base-unit, 8px)) calc(3 * var(--base-unit, 8px));
  height: calc(8 * var(--base-unit, 8px));
  line-height: calc(4 * var(--base-unit, 8px));
  box-sizing: border-box;
  cursor: pointer;
}
.svc-tabbed-menu-item:hover, .svc-tabbed-menu-item:focus {
  background-color: var(--background-dim, #f3f3f3);
  box-shadow: inset 0px -1px 0px var(--border, #d6d6d6);
  outline: none;
}

.svc-tabbed-menu-item--selected {
  background: var(--background, #fff);
  box-shadow: inset 0px -2px 0px var(--primary, #19b394);
}
.svc-tabbed-menu-item--selected:hover, .svc-tabbed-menu-item--selected:focus {
  background: var(--background, #fff);
  box-shadow: inset 0px -2px 0px var(--primary, #19b394);
}

.svc-tabbed-menu-item--disabled {
  cursor: default;
  color: var(--foreground, #161616);
  opacity: 0.25;
}
.svc-tabbed-menu-item--disabled:hover {
  background-color: var(--background, #fff);
}

.svc-tabbed-menu-item--hidden {
  visibility: hidden;
}

.svc-tabbed-menu-item .svc-tabbed-menu-item__text {
  white-space: nowrap;
  line-height: calc(4 * var(--base-unit, 8px));
}

.svc-tabbed-menu-item-container .sv-dots__item {
  width: auto;
}