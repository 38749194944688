.svc-rating-question-controls {
  position: absolute;
}

.svc-question__content--in-popup .svc-rating-question-controls.svc-item-value-controls {
  display: flex;
}

.svc-question__content--selected .svc-rating-question-controls.svc-item-value-controls {
  display: flex;
}

.svc-question__content .sd-rating {
  margin-inline-start: calc(9 * var(--base-unit, 8px));
  width: calc(100% - 9 * var(--base-unit, 8px));
}
.svc-question__content .sd-question--table .sd-rating {
  margin-inline-start: 0;
  width: fit-content;
  margin: auto;
}
.svc-question__content .svc-rating-question-controls {
  display: flex;
  width: calc(8 * var(--base-unit, 8px));
  min-width: auto;
  justify-content: flex-start;
}
.svc-question__content .sd-rating__item--fixed-size:focus-within {
  width: unset;
  padding: calc(0.5 * var(--base-unit, 8px)) calc(2.5 * var(--base-unit, 8px));
}